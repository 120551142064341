<template>
  <div class="waves">
    <Waves class="waves-svg" />
  </div>
</template>

<script>
import Waves from '@/assets/illustrations/waves-small.svg'

export default {
  components: {
    Waves,
  },
}
</script>
