<template>
  <Logo class="logo" />
</template>

<script>
import Logo from '@/assets/illustrations/ricaim-logo.svg'

export default {
  components: { Logo },
}
</script>

<style lang="sass">
.logo
    height: 3rem
    .st0
        fill:#18191A
    .st1
        fill:#00A7DC
    .st2
        fill:#202021
    .st3
        fill:#1D1C1D
    .st4
        fill:#181A1C
    .st5
        fill:#313132
    .st6
        fill:#343335
    .st7
        fill:#222223
    .st8
        fill:#363537
    .st9
        fill:#212B31
    .st10
        fill:#171C1F
    .st11
        fill:#EBECED
</style>
